import { createContext, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import type { User } from "firebase/auth";

import { auth } from "../api/config";

import { Internal } from "../components/Internal/Internal";
import { External } from "../components/External/External";
import { ErrorBoundary } from "../ErrorBoundary";

export type AuthContextValue = User | null | undefined;

export const AuthContext = createContext<AuthContextValue>(undefined);

export const AuthProvider = () => {
  const [user, loading, error] = useAuthState(auth);

  const render = () => {
    if (loading) {
      return (
        <Center h="100vh">
          <Spinner size="lg" />
        </Center>
      );
    }

    if (error) {
      return (
        <Center h="100vh">
          <VStack justify="center">
            <Heading size="xs">Authentication Error</Heading>
            <Text>There was an error with logging in to your account. Please</Text>
            <a href="mailto:hackathon2024@apexon.com" target="_blank" rel="noreferrer">
              <Text color="brand.500" fontWeight="semibold">
                contact us
              </Text>
              <Text>at hackathon2024@apexon.com</Text>
            </a>
          </VStack>
        </Center>
      );
    }

    return user ? <Internal /> : <External />;
  };

  return (
    <AuthContext.Provider value={user}>
      <ErrorBoundary>{render()}</ErrorBoundary>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
