import { Stack } from "@chakra-ui/react";
import { TextInput } from "../../components/reusable/TextInput";
import { RadioInput } from "../../components/reusable/RadioInput";
import { TextareaInput } from "../../components/reusable/TextareaInput";
import { Errors, Values } from "./TeamFormPage";
import { DollarInput } from "../../components/reusable/DollarInput";

export type TeamFormInputsProps = {
  edit?: boolean;
  values: Values;
  errors: Errors;
  onChange: (name: string, value: string) => void;
};

export const TeamFormInputs = ({ edit, values, errors, onChange }: TeamFormInputsProps) => (
  <Stack w="600px" spacing="8" px="6" py="6">
    <TextInput
      name="id"
      label="Team ID"
      value={values.id}
      error={errors.id}
      onChange={onChange}
      maxW="200px"
      required
      disabled={edit}
    />
    <TextInput
      name="name"
      label="Team Name"
      value={values.name}
      error={errors.name}
      onChange={onChange}
      maxW="300px"
      required
    />
    <TextareaInput
      name="idea"
      label="Team Idea"
      value={values.idea}
      error={errors.idea}
      onChange={onChange}
      required
    />
    <RadioInput
      name="cloudRequired"
      label="Do you need access to a cloud provider to implement your idea?"
      value={values.cloudRequired}
      options={[
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" },
      ]}
      onChange={onChange}
    />
    {values.cloudRequired === "Yes" && (
      <>
        <RadioInput
          name="cloudProvider"
          label="Which cloud provider would you like access to?"
          value={values.cloudProvider}
          options={[
            { label: "Azure", value: "Azure" },
            { label: "AWS", value: "AWS" },
          ]}
          onChange={onChange}
        />
        <DollarInput
          min={0}
          max={50}
          name="cloudBudget"
          label="What do you anticipate your required cloud budget to be?"
          value={values.cloudBudget}
          helper="The minimum amount is $0 and the maximum amount is $50"
          onChange={onChange}
        />
      </>
    )}
  </Stack>
);
