import { createIcon } from "@chakra-ui/react";

export const ApexonIcon = createIcon({
  displayName: "ApexonIcon",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 1080 1080">
      <rect width="100%" height="100%" fill="transparent"></rect>
      <rect
        width="1080"
        height="1080"
        x="-540"
        y="-540"
        fill="#FFF"
        rx="0"
        ry="0"
        transform="translate(540 540)"
        vectorEffect="non-scaling-stroke"
        visibility="hidden"
      ></rect>
      <path
        fill="#E64A24"
        d="M-3.565-3.097l-2.685 4.65-1.08 1.855A21.185 21.185 0 005.03 7.366a21.1 21.1 0 002.3-.125l-8.429-14.6z"
        transform="translate(-714.04 4007.41) scale(249.25)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fill="#E64A24"
        d="M438.365 590.063l-2.685 4.65-1.08 1.855a21.185 21.185 0 0012.36 3.958 21.1 21.1 0 002.3-.125l-8.429-14.6z"
        transform="translate(413.31 892.23) scale(25.26) translate(-441.93 -593.16)"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        fill="#E64A24"
        d="M397.3 412.066l-3.308-5.73 3.626-6.284 6.7 11.585 4.166 7.211a21.13 21.13 0 004.263-12.775 21.273 21.273 0 10-38.283 12.775l4.166-7.211 6.7-11.585 3.626 6.284 11.114 19.211a21.187 21.187 0 003.778-2.159zm-9.439-16.374l3.626-6.284 3.626 6.284-3.626 6.284z"
        transform="translate(540.41 518.05) scale(25.26) translate(-391.47 -405.17)"
        vectorEffect="non-scaling-stroke"
      ></path>
    </svg>
  ),
});
