import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "./context/AuthContext";
import { theme } from "./theme";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider />
    </ChakraProvider>
  );
}

export default App;
