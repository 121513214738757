import { useEffect, useState } from "react";
import { collection, FirestoreError, onSnapshot } from "firebase/firestore";
import { firestore } from "../api/config";
import type { Team } from "../types";

type Query<T> = {
  data: T | null;
  loading: boolean;
  error: FirestoreError | null;
};

export const useQueryTeams = () => {
  const [query, setQuery] = useState<Query<Team[]>>({
    data: null,
    loading: false,
    error: null,
  });

  const fetchTeams = async () => {
    try {
      setQuery((prev) => ({ ...prev, loading: true }));

      onSnapshot(collection(firestore, "teams"), (snapshot) => {
        const response: Team[] = [];

        snapshot.forEach((doc) => {
          response.push(doc.data() as Team);
        });

        setQuery((prev) => ({ ...prev, loading: false, data: response }));
      });
    } catch (error) {
      setQuery((prev) => ({ ...prev, loading: false, error: error as FirestoreError }));
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  return [query.data, query.loading, query.error] as [Team[] | null, boolean, FirestoreError | null];
};
