import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

export type ConfirmationModalProps = {
  title: string;
  body: React.ReactNode;
  type: "neutral" | "constructive" | "destructive";
  action: string;
  onClose: () => void;
  onClick: () => void;
};

export const ConfirmationModal = ({
  title,
  body,
  action,
  type,
  onClose,
  onClick,
}: ConfirmationModalProps) => {
  const colorScheme = {
    neutral: "brand",
    constructive: "green",
    destructive: "red",
  }[type];

  return (
    <Modal onClose={onClose} isOpen={true} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="0" color={`${colorScheme}.500`} fontWeight="700">
          {title}
        </ModalHeader>
        <ModalBody color="muted">{body}</ModalBody>
        <ModalFooter mt="30px">
          <HStack>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme={colorScheme} onClick={onClick}>
              {action}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
