import { Box, Button, Flex } from "@chakra-ui/react";
import { FaDoorOpen, FaQuestionCircle } from "react-icons/fa";
import { userSignOut } from "../../../../api/auth";

export type FlowWrapperProps = {
  hideSignOut?: boolean;
  children: React.ReactNode;
};

export const FlowWrapper = ({ hideSignOut, children }: FlowWrapperProps) => (
  <Box position="relative">
    <Box position="absolute" top="10px" right="10px">
      <Flex>
        <a
          href={process.env.REACT_APP_FAQ_LINK || ""}
          target="_blank"
          style={{ display: "flex", alignItems: "center", height: "auto", padding: "16px 12px" }}
        >
          <Button
            h="auto"
            display="flex"
            alignItems="center"
            size="sm"
            variant="ghost"
            _hover={{ color: "blue.500" }}
            _active={{ color: "blue.600" }}
            leftIcon={<FaQuestionCircle />}
          >
            FAQ
          </Button>
        </a>
        {hideSignOut || (
          <Button
            h="auto"
            py="4"
            size="sm"
            variant="ghost"
            _hover={{ color: "red.500" }}
            _active={{ color: "red.600" }}
            leftIcon={<FaDoorOpen />}
            onClick={userSignOut}
          >
            Sign out
          </Button>
        )}
      </Flex>
    </Box>
    {children}
  </Box>
);
