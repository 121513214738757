import { Box, Button, Flex, Heading, Highlight, Stack, Text } from "@chakra-ui/react";
import { ApexonIcon } from "../components/ApexonIcon";
import { FlowWrapper } from "../../Internal/components/reusable/FlowWrapper";
import { userSignIn } from "../../../api/auth";

export const LoginPage = () => {
  return (
    <FlowWrapper hideSignOut>
      <Stack h="100vh" w="100vw" justify="center" align="center" textAlign="center" spacing="12">
        <Box>
          <Heading size="sm" fontWeight="extrabold" letterSpacing="tight" lineHeight="tall">
            <Highlight query="Hackathon" styles={{ color: "brand.500" }}>
              Welcome to Apexon GenAI Hackathon
            </Highlight>
          </Heading>
          <Text fontSize="lg" color="muted">
            Please login using your Apexon account
          </Text>
        </Box>
        <Box>
          <Button
            mb="10px"
            variant="secondary"
            leftIcon={<ApexonIcon boxSize="5" />}
            iconSpacing="2"
            onClick={userSignIn}
          >
            Login with Apexon
          </Button>
          <Box maxW="400px">
            {window.location.origin.includes("https://hackathon.apexon.com") ? (
              <Text fontSize="sm" color="muted">
                Having issues? Try using the Safari browser if you're using a Mac or{" "}
                <a
                  href="https://apexon-hackathon-2023.firebaseapp.com/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Highlight
                    query="use this link instead"
                    styles={{ color: "brand.500", fontWeight: "semibold" }}
                  >
                    use this link instead
                  </Highlight>
                </a>
              </Text>
            ) : (
              <Text fontSize="sm" color="muted">
                Having issues?{" "}
                <a href="mailto:hackathon2024@apexon.com" target="_blank" rel="noreferrer">
                  <Highlight query="Contact us" styles={{ color: "brand.500", fontWeight: "semibold" }}>
                    Contact us
                  </Highlight>
                </a>
              </Text>
            )}
          </Box>
        </Box>
      </Stack>
    </FlowWrapper>
  );
};
