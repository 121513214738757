import { createContext, useContext } from "react";
import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { useQueryParticipants } from "../hooks/useQueryParticipants";
import { useQueryTeams } from "../hooks/useQueryTeams";
import { AuthContext } from "./AuthContext";
import type { User } from "firebase/auth";
import type { Participant, Team } from "../types";

export type DataContextValue = {
  participants: Participant[];
  participant?: Participant;
  teams: Team[];
  team?: Team;
} | null;

export type DataProviderProps = {
  children: React.ReactNode;
};

export const deriveIdFromEmail = (email: string | undefined | null) => {
  // email format is always firstName.lastName@apexon.com
  return email?.split("@")[0].split(".").join("-");
};

const findParticipant = (participants: Participant[] | null, user: User | null | undefined) => {
  return participants?.find((p) => p.email === user?.email);
};

const findTeam = (teams: Team[] | null, user: User | null | undefined) => {
  return teams?.find((t) => t.members.find((m) => m.id === deriveIdFromEmail(user?.email)));
};

export const DataContext = createContext<DataContextValue>(null);

export const DataProvider = ({ children }: DataProviderProps) => {
  const user = useContext(AuthContext);

  const [participants, _pLoading, pError] = useQueryParticipants();
  const [teams, _tLoading, tError] = useQueryTeams();

  const error = pError || tError || "";

  const participant = findParticipant(participants, user);
  const team = findTeam(teams, user);

  if (error) {
    return (
      <Center h="100vh">
        <VStack justify="center">
          <Heading size="xs">Authorization Error</Heading>
          <Text>There was an error with getting access to the portal. Please</Text>
          <a href="mailto:hackathon2024@apexon.com" target="_blank" rel="noreferrer">
            <Text color="brand.500" fontWeight="semibold">
              contact us
            </Text>
            <Text>at hackathon2024@apexon.com</Text>
          </a>
        </VStack>
      </Center>
    );
  }

  if (!participants || !teams) {
    return (
      <Center h="100vh">
        <Spinner size="lg" colorScheme="" />
      </Center>
    );
  }

  return (
    <DataContext.Provider value={{ participants, participant, teams, team }}>{children}</DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
