import { Button, Center, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { Component } from "react";

export class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Center h="100vh">
          <VStack justify="center">
            <Heading size="xs">Something went wrong</Heading>
            <Flex gap="4px" justify="center" align="center">
              <Text color="muted">If this issue persists even after reloading, please</Text>
              <a href="mailto:hackathon2024@apexon.com" target="_blank" rel="noreferrer">
                <Text color="brand.500" fontWeight="semibold">
                  contact us
                </Text>
              </a>
              <Text color="muted">at hackathon2024@apexon.com</Text>
            </Flex>
          </VStack>
        </Center>
      );
    }

    return this.props.children;
  }
}
