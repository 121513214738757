import { Stack, VStack } from "@chakra-ui/react";
import { NavigationHeader } from "./NavigationHeader";
import { NavigationBody } from "./NavigationBody";
import { NavigationFooter } from "./NavigationFooter";
import { useAuth } from "../../../../context/AuthContext";

export const Navigation = () => {
  const auth = useAuth();

  return (
    <VStack
      w="260px"
      h="100%"
      pos="fixed"
      justify="space-between"
      align="stretch"
      bg="gray.50"
      borderRightWidth="1px"
      borderRightColor="gray.200"
    >
      <Stack w="100%" spacing="3">
        <NavigationHeader name={auth?.displayName || ""} />
        <NavigationBody />
      </Stack>
      <NavigationFooter />
    </VStack>
  );
};
