import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  Button,
  Tooltip,
  useToast,
  useDisclosure,
  Highlight,
} from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import { useData } from "../../../../context/DataContext";
import { Participant } from "../../../../types";
import { TeamMembers } from "./TeamMembers";
import {
  acceptRequest,
  finalizeTeam,
  rejectRequest,
  removeMember,
  sendRequest,
} from "../../../../api/actions";
import { triggerFailureToast, triggerSuccessToast } from "../../../../utils/toasts";
import { ideationPhase, teamFormationPhase } from "../../../../constants/env";
import { ConfirmationModal } from "../../components/reusable/ConfirmationModal";
import { Loader } from "../../components/reusable/Loading";

type ObjectWithId = {
  id: string;
}[];

const mapJoin = <A extends ObjectWithId, B extends ObjectWithId>(outer?: A, inner?: B) => {
  return outer?.map((o) => inner?.find((i) => i.id === o.id));
};

const FINALIZE_TEAM_MSG = "Finalizing your team is an irreversible action and will lock your team members in place. You will, however, still be able to edit your team details (including the idea)."
const REVIEW_TEAM_MSG = `When finalizing your team, it's better to consider having 3-6 team members, with at least one person of a different gender and at least one person from a different location. This can earn you additional points. \n \n \n ${FINALIZE_TEAM_MSG}`
export const TeamPage = () => {
  const { id } = useParams();

  const data = useData();
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!data) {
    return <Loader />;
  }

  const { participant, participants } = data;

  const team = data.teams.find((team) => team.id === id);

  if (!team) {
    return (
      <Flex w="100%" h="100vh" direction="column" justify="center" align="center" p="100px">
        <Heading size="sm" color="brand.500" fontWeight="extrabold" mb="12px">
          You are currently not a part of any team
        </Heading>
        <Text fontSize="lg" color="muted">
          You can{" "}
          <Link to="/create-team">
            <Highlight query="create your own team" styles={{ color: "blue.500", fontWeight: "semibold" }}>
              create your own team
            </Highlight>
          </Link>{" "}
          or{" "}
          <Link to="/teams">
            <Highlight query="find existing teams" styles={{ color: "blue.500", fontWeight: "semibold" }}>
              find existing teams
            </Highlight>
          </Link>{" "}
          in the participant directory
        </Text>
      </Flex>
    );
  }

  const members = mapJoin(team.members, participants) as Participant[];
  const invites = mapJoin(team.invites, participants) as Participant[];
  const requests = mapJoin(team.requests, participants) as Participant[];

  const isUserMember = team.id === data.team?.id;
  const hasUserRequested = Boolean(team.requests.find((r) => r.id === participant?.id));

  if (teamFormationPhase === "inactive" && team.open) {
    finalizeTeam(team);
  }

  const teamMeetsSizeRequirements = 3 <= members.length && members.length <= 6;
  const teamMeetsAllRequirements = teamMeetsSizeRequirements;

  const onClickAcceptRequest = (participant: Participant) => {
    acceptRequest(data.teams, team, participant.id)
      .then(() =>
        triggerSuccessToast(toast, {
          title: "Request Accepted",
          description: `You have successfully accepted the request from ${participant.email}`,
        })
      )
      .catch(() =>
        triggerFailureToast(toast, {
          title: "Error",
          description:
            "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
        })
      );
  };

  const onClickRejectRequest = (participant: Participant) => {
    rejectRequest(team, participant.id)
      .then(() =>
        triggerSuccessToast(toast, {
          title: "Request Rejected",
          description: `You have successfully rejected the request from ${participant.email}`,
        })
      )
      .catch(() =>
        triggerFailureToast(toast, {
          title: "Error",
          description:
            "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
        })
      );
  };

  const onClickSendRequest = () => {
    participant &&
      sendRequest(team, participant.id)
        .then(() =>
          triggerSuccessToast(toast, {
            title: "Request Sent",
            description: `You have successfully sent a join request to ${team.name}`,
          })
        )
        .catch(() =>
          triggerFailureToast(toast, {
            title: "Error",
            description:
              "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
          })
        );
  };

  const onClickFinalizeTeam = () => {
    finalizeTeam(team)
      .then(() => {
        triggerSuccessToast(toast, {
          title: "Team Finalized",
          description: "You have successfully finalized your team",
        });
        onClose();
      })
      .catch(() =>
        triggerFailureToast(toast, {
          title: "Error",
          description:
            "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
        })
      );
  };

  const onClickRemoveMember = (participant: Participant) => {
    removeMember(team, participant.id)
      .then(() =>
        triggerSuccessToast(toast, {
          title: "Member Removed",
          description: `You have successfully removed team member with email ${participant.email}`,
        })
      )
      .catch(() =>
        triggerFailureToast(toast, {
          title: "Error",
          description:
            "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
        })
      );
  };

  const onClickLeaveTeam = (participant: Participant) => {
    removeMember(team, participant.id)
      .then(() => {
        triggerSuccessToast(toast, {
          title: "Team Left",
          description: `You have successfully left your team with name ${team.name}`,
        });

        navigate("/teams");
      })
      .catch(() =>
        triggerFailureToast(toast, {
          title: "Error",
          description:
            "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
        })
      );
  };

  return (
    <Box width="100%">
      <Box inset="0" height="48" bg="brand.600" />
      <Flex justify="center" align="center">
        <Flex w="100%" maxW="1200px" direction="column" position="relative" top="-64px" gap="20px">
          <Flex w="100%" bg="white" shadow="base" rounded="lg" display="inline-block" overflow="hidden">
            <Box px="20px" py="20px">
              <Flex justify="space-between" align="center">
                <HStack align="center" spacing="2">
                  <Heading size="xs" fontWeight="extrabold" letterSpacing="tight">
                    {team.name}
                  </Heading>
                  <Text>
                    {team.open ? (
                      <Badge colorScheme="green">Open</Badge>
                    ) : (
                      <Badge colorScheme="red">Closed</Badge>
                    )}
                  </Text>
                </HStack>

                {isUserMember ? (
                  <HStack>
                    {ideationPhase === "active" && (
                      <Button size="sm" colorScheme="blue" onClick={() => navigate("/update-team")}>
                        Edit Team
                      </Button>
                    )}
                    <Tooltip
                      label={
                        team.open
                          ? "Finalizing your team is an irreversible action and will lock your team in place"
                          : "Your team has been finalized and no further member changes can be made"
                      }
                    >
                      <Button size="sm" colorScheme="brand" onClick={onOpen} isDisabled={!team.open}>
                        Finalize Team
                      </Button>
                    </Tooltip>
                  </HStack>
                ) : (
                  <Tooltip
                    label={
                      team.open
                        ? hasUserRequested
                          ? "You have already sent a request to this team"
                          : ""
                        : "The team is closed from new members"
                    }
                  >
                    <Button
                      size="sm"
                      colorScheme="brand"
                      isDisabled={!team.open || hasUserRequested}
                      onClick={onClickSendRequest}
                    >
                      Request to Join
                    </Button>
                  </Tooltip>
                )}
              </Flex>
              {(isUserMember || ideationPhase === "inactive") && (
                <Text color="gray.500" mt="10px">
                  {team.idea}
                </Text>
              )}
            </Box>
            <Box>
              <TeamMembers
                members={members}
                isMember={isUserMember}
                isTeamOpen={team.open}
                onClickRemoveMember={onClickRemoveMember}
                onClickLeaveTeam={onClickLeaveTeam}
              />
            </Box>
            <Box px="6" py="4">
              <HStack spacing="1" fontSize="sm" color="gray.500">
                <Icon as={HiUsers} />
                <Text>
                  {members.length} member{members.length !== 1 && "s"}
                </Text>
              </HStack>
            </Box>
          </Flex>
          {team.open && isUserMember && (
            <Flex gap="20px" align="flex-start">
              <Flex flex="1" bg="white" shadow="base" rounded="lg">
                <Box w="100%" rounded="lg" display="inline-block" overflow="hidden">
                  <Box px="20px" py="20px">
                    <Text fontSize="1.2rem" fontWeight="bold">
                      Requests
                    </Text>
                    <Text color="gray.500">
                      This is the list of participants requesting to join your team
                    </Text>
                  </Box>
                  <Box>
                    <TeamMembers
                      members={requests}
                      isRequest={isUserMember}
                      onClickAcceptRequest={onClickAcceptRequest}
                      onClickRejectRequest={onClickRejectRequest}
                    />
                  </Box>
                </Box>
              </Flex>
              <Flex flex="1" bg="white" shadow="base" rounded="lg">
                <Box w="100%" rounded="lg" display="inline-block" overflow="hidden">
                  <Box px="20px" py="20px">
                    <Text fontSize="1.2rem" fontWeight="bold">
                      Invites
                    </Text>
                    <Text color="gray.500">This is the list of participants invited to join your team</Text>
                  </Box>
                  <Box>
                    <TeamMembers members={invites} isInvite={isUserMember} />
                  </Box>
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ConfirmationModal
          type="neutral"
          title={
            "Are you sure you want to continue?"
          }
          body={
            teamMeetsAllRequirements
              ? FINALIZE_TEAM_MSG
              : REVIEW_TEAM_MSG
          }
          action={"Finalize"}
          onClose={onClose}
          onClick={onClickFinalizeTeam}
        />
      )}
    </Box>
  );
};
