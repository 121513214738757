import { Avatar, Flex, Heading, HStack, Icon, Stack, Tag, Text, Wrap } from "@chakra-ui/react";
import { FaLandmark, FaMap } from "react-icons/fa";
import { Participant } from "../../../../types";

export type ParticipantInfoProps = {
  participant: Participant;
};

export const ParticipantInfo = ({ participant }: ParticipantInfoProps) => (
  <Flex direction="column" align="center" p="30px" mb="30px">
    <Avatar
      mt="-72px"
      mb="16px"
      borderWidth="6px"
      borderColor="white"
      size="xl"
      name={`${participant.fName} ${participant.lName}`}
      color="gray.500"
      bg="gray.300"
    />
    <Flex direction="column" align="center" mb="30px">
      <Heading size="md" fontWeight="extrabold" letterSpacing="tight">
        {participant.fName} {participant.lName}
      </Heading>
      <Text color="gray.600">{participant.position}</Text>
      <a href={`mailto:${participant.email}`} target="_blank" rel="noreferrer">
        <Text color="blue.500" fontSize="sm" fontWeight="500">
          {participant.email}
        </Text>
      </a>
    </Flex>
    <Flex direction="column" align="center" px="50px">
      <Stack direction="row" spacing="6" mt="4" fontSize="sm" fontWeight="medium" color="brand.500">
        <HStack>
          <Icon color="brand.500" as={FaMap} />
          <Text color="muted" fontWeight="500">
            {participant.city}, {participant.country} ({participant.timezone})
          </Text>
        </HStack>
      </Stack>

      <Text color="gray.500" textAlign="center" mt="20px">
        {participant.bio}
      </Text>

      <Flex gap="5px" mt="20px" justify="center" wrap="wrap">
        {participant.skills.map((skill) => (
          <Tag size="md" key={skill}>
            {skill}
          </Tag>
        ))}
      </Flex>
    </Flex>
  </Flex>
);
