import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";

import { TeamFormPage } from "./pages/TeamFormPage/TeamFormPage";
import { ParticipantsPage } from "./pages/ParticipantsPage/ParticipantsPage";
import { ParticipantPage } from "./pages/ParticipantPage/ParticipantPage";
import { TeamsPage } from "./pages/TeamsPage/TeamsPage";
import { TeamPage } from "./pages/TeamPage/TeamPage";

import { PageWrapper } from "./components/reusable/PageWrapper";
import { useData } from "../../context/DataContext";
import { participantRegistrationPhase, teamFormationPhase } from "../../constants/env";
import { RegistrationDonePage } from "./pages/RegistrationDonePage/RegistrationDonePage";
import { RegistrationFormPage } from "./pages/RegistrationFormPage/RegistrationFormPage";
import { RegistrationClosedPage } from "./pages/RegistrationClosedPage/RegistrationClosedPage";

export const routes = [
  {
    path: "/create-team",
    element: (
      <PageWrapper>
        <TeamFormPage />
      </PageWrapper>
    ),
  },
  {
    path: "/update-team",
    element: (
      <PageWrapper>
        <TeamFormPage edit />
      </PageWrapper>
    ),
  },
  {
    path: "/participants",
    element: (
      <PageWrapper>
        <ParticipantsPage />
      </PageWrapper>
    ),
  },
  {
    path: "/participants/:id",
    element: (
      <PageWrapper>
        <ParticipantPage />
      </PageWrapper>
    ),
  },
  {
    path: "/teams",
    element: (
      <PageWrapper>
        <TeamsPage />
      </PageWrapper>
    ),
  },
  {
    path: "/teams/:id",
    element: (
      <PageWrapper>
        <TeamPage />
      </PageWrapper>
    ),
  },
  {
    path: "/user-registration",
    element: <RegistrationFormPage />,
  },
  {
    path: "*",
    element: <Navigate to="/participants" />,
  },
];

const router = createBrowserRouter(routes);

export const Routing = () => {
  const data = useData();

  const participantExists = Boolean(data?.participant);

  if (!participantExists) {
    if (participantRegistrationPhase === "active") {
      return <RegistrationFormPage />;
    } else {
      return <RegistrationClosedPage />;
    }
  } else {
    if (teamFormationPhase === "active" || participantRegistrationPhase === "inactive") {
      return <RouterProvider router={router} />;
    } else {
      return <RegistrationDonePage />;
    }
  }
};
