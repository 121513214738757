import { DataProvider } from "../../context/DataContext";
import { FiltersProvider } from "../../context/FiltersContext";
import { Routing } from "./Routing";

export const Internal = () => (
  <DataProvider>
    <FiltersProvider>
      <Routing />
    </FiltersProvider>
  </DataProvider>
);
