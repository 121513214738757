import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Highlight, Input } from "@chakra-ui/react";

export type TextInputProps = {
  name: string;
  label?: string;
  value: string;
  error?: string;
  maxW?: string;
  helper?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: (name: string, value: string) => void;
};

export const TextInput = ({
  name,
  label,
  value,
  error,
  maxW,
  helper,
  required,
  disabled,
  placeholder,
  onChange,
}: TextInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.target.value);
  };

  return (
    <FormControl isInvalid={Boolean(error)} maxW={maxW}>
      {label && (
        <FormLabel>
          {label}{" "}
          {required && (
            <Highlight query="*" styles={{ color: "red.500" }}>
              *
            </Highlight>
          )}
        </FormLabel>
      )}
      <Input value={value} onChange={handleChange} placeholder={placeholder} isDisabled={disabled} />
      {error ? (
        <FormErrorMessage>{error}</FormErrorMessage>
      ) : (
        helper && <FormHelperText>{helper}</FormHelperText>
      )}
    </FormControl>
  );
};
