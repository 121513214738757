import type { FiltersValue } from "../context/FiltersContext";

const FILTERS_KEY = "filters";

const saveData = <T>(key: string, data: T) => {
  const text = JSON.stringify(data);
  localStorage.setItem(key, text);
};

const loadData = <T>(key: string): T => {
  const text = localStorage.getItem(key);
  return text && JSON.parse(text);
};

export const saveFilters = (filters: FiltersValue) => {
  return saveData(FILTERS_KEY, filters);
};

export const loadFilters = () => {
  return loadData<FiltersValue>(FILTERS_KEY);
};
