export const ApexonLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 165 42.962" width="150px">
    <g id="Apexon_Logo" data-name="Apexon Logo" transform="translate(-370.2 -384.8)">
      <g id="Group_737" data-name="Group 737" transform="translate(370.2 384.8)">
        <path
          id="Path_39_00000044174289358051436440000010869278548088471997_"
          d="M1448.313,515.978A7.925,7.925,0,0,0,1435.1,522.1v7.28a.526.526,0,0,0,.526.526h1.813a.514.514,0,0,0,.512-.526V522.1a5.186,5.186,0,0,1,4.484-5.246,5.009,5.009,0,0,1,4,1.3,5.345,5.345,0,0,1,1.661,3.931v7.28a.526.526,0,0,0,.526.526h1.813a.526.526,0,0,0,.526-.526v-7.446a8.044,8.044,0,0,0-2.644-5.938"
          transform="translate(-1287.706 -496.075)"
          fill="#565655"
        ></path>
        <path
          id="Path_40_00000142866184761528925640000007946770770556448425_"
          d="M950.372,513.8a8.027,8.027,0,0,0-7.958,7.792c0,.028-.014.042-.014.069v16.72a.523.523,0,0,0,.512.526h1.827a.514.514,0,0,0,.512-.526V527.945a7.989,7.989,0,1,0,5.121-14.145m5.093,8a5.093,5.093,0,1,1-5.107-5.121,5.136,5.136,0,0,1,5.107,5.121"
          transform="translate(-863.204 -495.945)"
          fill="#565655"
        ></path>
        <path
          id="Path_41_00000150786134300899080480000001317317098220538025_"
          d="M1087.088,515.966a7.959,7.959,0,0,0-5.868-2.215,8.029,8.029,0,0,0-4.277,14.519,7.426,7.426,0,0,0,2.63,1.246c.221.055.457.1.692.138.111.014.208.042.318.055h.055l.429.042h.069a.382.382,0,0,1,.125.014h.429a3.625,3.625,0,0,0,.388-.014.676.676,0,0,0,.166-.014,7.8,7.8,0,0,0,3.557-1.177h.014a8.076,8.076,0,0,0,1.273-1,.481.481,0,0,0,.152-.374.546.546,0,0,0-.166-.387l-1.315-1.273a.518.518,0,0,0-.72,0,5.241,5.241,0,0,1-1.2.844.747.747,0,0,1-.152.069,1.651,1.651,0,0,1-.277.111,1.211,1.211,0,0,1-.208.069c-.1.028-.208.069-.3.1a.324.324,0,0,1-.111.028,2.644,2.644,0,0,1-.457.083h-.014a6.693,6.693,0,0,1-1.052.042,3.2,3.2,0,0,1-.36-.042h-.014a5.213,5.213,0,0,1-1.038-.277,7.225,7.225,0,0,1-1.038-.512,5.189,5.189,0,0,1-2.09-2.837h11.419a1.447,1.447,0,0,0,1.44-1.439,8.031,8.031,0,0,0-2.491-5.8m-.609,4.346h-9.772a5.08,5.08,0,0,1,8.4-2.256,5.005,5.005,0,0,1,1.37,2.256"
          transform="translate(-976.266 -495.897)"
          fill="#565655"
        ></path>
        <path
          id="Path_43_00000005263081005852560640000001904156622431503762_"
          d="M1312.714,513.8a8.014,8.014,0,1,0,7.972,8.014,8.01,8.01,0,0,0-7.972-8.014m5.08,8a5.073,5.073,0,1,1-5.08-5.121,5.1,5.1,0,0,1,5.08,5.121"
          transform="translate(-1175.359 -495.945)"
          fill="#565655"
        ></path>
        <path
          id="Path_44_00000044888910707214440360000008491158967935939000_"
          d="M780.191,485.421a2.041,2.041,0,0,0-.18-.484.413.413,0,0,0-.055-.111s-8.166-17.716-9.771-21.19a.879.879,0,0,0-.844-.54h-1.689a.905.905,0,0,0-.844.54c-1.605,3.474-9.772,21.19-9.772,21.19a.814.814,0,0,1-.055.111,2.046,2.046,0,0,0-.18.484v.1c0,.028.014.055.014.083a.739.739,0,0,0,.706.471h1.8a.923.923,0,0,0,.927-.54c.318-.678,2.1-4.567,2.1-4.567h12.291s1.785,3.889,2.1,4.567a.9.9,0,0,0,.927.54h1.8a.733.733,0,0,0,.706-.471.176.176,0,0,0,.014-.083v-.1m-16.429-7.557L768.5,467.47l4.733,10.394Z"
          transform="translate(-703.292 -452.259)"
          fill="#565655"
        ></path>
        <path
          id="Path_2961"
          data-name="Path 2961"
          d="M1209.662,528.89h0l-1.9-2.685h0c-1.038-1.481-2.09-2.948-3.128-4.429l2.491-3.529v.028c.844-1.19,1.675-2.367,2.519-3.557l.056-.083a.725.725,0,0,0,.194-.5l-.014-.069a.484.484,0,0,0-.5-.291h-2.394a.97.97,0,0,0-.83.471l-1.01,1.481c-.775,1.135-1.55,2.27-2.325,3.419-1.135-1.647-2.256-3.28-3.391-4.927a.97.97,0,0,0-.817-.443h-2.367a.484.484,0,0,0-.5.291l-.014.069a.828.828,0,0,0,.194.5l.055.083c.844,1.19,1.689,2.367,2.519,3.557.83,1.163,1.661,2.339,2.478,3.5-1.038,1.467-2.09,2.948-3.128,4.415l-1.979,2.782a.5.5,0,0,0-.125.512.405.405,0,0,0,.374.263c.3,0,.609.014.914.014h1.522a.977.977,0,0,0,.858-.443c.443-.637.886-1.259,1.315-1.9l.983-1.412c.152-.235.318-.471.484-.706.208-.291.415-.581.609-.886.055.069.1.152.152.221l1.329,1.924c.623.913,1.246,1.813,1.869,2.713a1,1,0,0,0,.886.471h1.522c.3,0,.609,0,.914-.014a.405.405,0,0,0,.374-.263.477.477,0,0,0-.125-.512Z"
          transform="translate(-1081.47 -495.922)"
          fill="#565655"
        ></path>
        <g id="Group_736" data-name="Group 736" transform="translate(0)">
          <path
            id="Path_2962"
            data-name="Path 2962"
            d="M438.365,590.063l-2.685,4.65-1.08,1.855a21.185,21.185,0,0,0,12.36,3.958h0a21.1,21.1,0,0,0,2.3-.125l-8.429-14.6Z"
            transform="translate(-425.687 -557.98)"
            fill="#e64a24"
          ></path>
          <path
            id="Path_2963"
            data-name="Path 2963"
            d="M397.3,412.066l-3.308-5.73,3.626-6.284,6.7,11.585,4.166,7.211a21.13,21.13,0,0,0,4.263-12.775h0a21.273,21.273,0,1,0-38.283,12.775l4.166-7.211,6.7-11.585,3.626,6.284h0l11.114,19.211a21.187,21.187,0,0,0,3.778-2.159Zm-9.439-16.374,3.626-6.284,3.626,6.284-3.626,6.284Z"
            transform="translate(-370.2 -384.8)"
            fill="#e64a24"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
