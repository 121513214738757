import type { FiltersValue } from "../context/FiltersContext";
import type { FiltersSchema, Participant, Team } from "../types";

export const filtersSchema: FiltersSchema = {
  city: {
    title: "City",
    options: [
      "Santa Clara",
      "New York",
      "Dallas",
      "Chicago",
      "Southfield",
      "Princeton",
      "Dublin",
      "Bellevue",
      "Ahmedabad",
      "Pune",
      "Chennai",
      "Bangalore",
      "Coimbatore",
      "Hyderabad",
      "Mumbai",
      "Sunderland",
      "Other",
    ],
  },
  country: {
    title: "Country",
    options: ["India", "US", "UK"],
  },
  timezone: {
    title: "Timezone",
    options: ["IST", "PST", "MST", "CST", "EST", "BST", "GMT"],
  },
  status: {
    title: "Status",
    options: ["Part of a Team", "Not Part of a Team"],
  },
};

export const filterParticipants = (participants: Participant[], teams: Team[], filters: FiltersValue) => {
  return participants.filter((p) => {
    // SEARCH
    if (
      !p.fName.toLowerCase().includes(filters.search.toLowerCase()) &&
      !p.lName.toLowerCase().includes(filters.search.toLowerCase()) &&
      !p.email.toLowerCase().includes(filters.search.toLowerCase()) &&
      !p.skills.join("*").toLowerCase().includes(filters.search.toLowerCase())
    ) {
      return false;
    }

    // CITY
    if (!filters.city.includes("Other") && !filters.city.includes(p.city)) {
      return false;
    }

    // COUNTRY
    if (!filters.country.includes(p.country)) {
      return false;
    }

    // TIMEZONE
    if (!filters.timezone.includes(p.timezone)) {
      return false;
    }

    // STATUS
    const status = teams.find((t) => t.members.find((m) => m.id === p.id))
      ? "Part of a Team"
      : "Not Part of a Team";

    if (!filters.status.includes(status)) {
      return false;
    }

    return true;
  });
};
