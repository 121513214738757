import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Highlight,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";

export type DollarInputProps = {
  name: string;
  label?: string;
  value: string;
  error?: string;
  max?: number;
  min?: number;
  helper?: string;
  required?: boolean;
  onChange: (name: string, value: string) => void;
};

export const DollarInput = ({
  name,
  label,
  value,
  error,
  max,
  min,
  helper,
  required,
  onChange,
}: DollarInputProps) => {
  return (
    <FormControl>
      <FormLabel>
        {label}{" "}
        {required && (
          <Highlight query="*" styles={{ color: "red.500" }}>
            *
          </Highlight>
        )}
      </FormLabel>
      <NumberInput
        min={min}
        max={max}
        value={"$" + value}
        onChange={(v) => onChange(name, v.replace(/^\$/, ""))}
      >
        <NumberInputField />
      </NumberInput>
      {error ? (
        <FormErrorMessage>{error}</FormErrorMessage>
      ) : (
        helper && <FormHelperText>{helper}</FormHelperText>
      )}
    </FormControl>
  );
};
