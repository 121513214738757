import { OAuthProvider, signInWithRedirect, signOut } from "firebase/auth";
import { auth } from "./config";

export const userSignIn = () => {
  const provider = new OAuthProvider("microsoft.com");

  provider.setCustomParameters({
    tenant: "isplahd.onmicrosoft.com",
  });

  signInWithRedirect(auth, provider);
};

export const userSignOut = () => {
  signOut(auth);
};
