import { useState } from "react";
import { deriveIdFromEmail, useData } from "../../../../context/DataContext";
import { Box, Button, Center, Flex, Heading, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { getPositionError, getLinkedinError } from "../../../../utils/validations";
import { TextInput } from "../../components/reusable/TextInput";
import { TextareaInput } from "../../components/reusable/TextareaInput";
import { CheckboxInput } from "../../components/reusable/CheckboxInput";
import { doc, setDoc } from "@firebase/firestore";
import { firestore } from "../../../../api/config";
import { City, Country, Participant, Skills, Timezone } from "../../../../types";
import { RadioInput } from "../../components/reusable/RadioInput";
import { useAuth } from "../../../../context/AuthContext";
import { triggerFailureToast } from "../../../../utils/toasts";
import { FlowWrapper } from "../../components/reusable/FlowWrapper";
import { ConfirmationModal } from "../../components/reusable/ConfirmationModal";
import { SelectInput } from "../../components/reusable/SelectInput";
import { ApexonLogo } from "./ApexonLogo";

type RegistrationValues = {
  city: City | "";
  country: Country | "";
  timezone: Timezone | "";
  position: string;
  linkedin: string;
  bio: string;
  skills: string[];
  bitbucket: "Yes" | "No" | "";
  additionalSkills: string;
};

type RegistrationErrors = {
  city: string;
  country: string;
  timezone: string;
  bitbucket: string;
  bio: string;
  position: string;
  linkedin: string;
};

export const cities: { label: string; value: City | "" }[] = [
  { label: "", value: "" },
  { label: "Ahmedabad", value: "Ahmedabad" },
  { label: "Bangalore", value: "Bangalore" },
  { label: "Bellevue", value: "Bellevue" },
  { label: "Chennai", value: "Chennai" },
  { label: "Chicago", value: "Chicago" },
  { label: "Coimbatore", value: "Coimbatore" },
  { label: "Dallas", value: "Dallas" },
  { label: "Dublin", value: "Dublin" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "New York", value: "New York" },
  { label: "Princeton", value: "Princeton" },
  { label: "Pune", value: "Pune" },
  { label: "Santa Clara", value: "Santa Clara" },
  { label: "Southfield", value: "Southfield" },
  { label: "Sunderland", value: "Sunderland" },
  { label: "Other", value: "Other" },
];

export const countries: { label: string; value: Country | "" }[] = [
  { label: "", value: "" },
  { label: "India", value: "India" },
  { label: "UK", value: "UK" },
  { label: "US", value: "US" },
];

export const timezones: { label: string; value: Timezone | "" }[] = [
  { label: "", value: "" },
  { label: "IST (GMT+5:30)", value: "IST" },
  { label: "PST (GMT-8:00)", value: "PST" },
  { label: "MST (GMT-7:00)", value: "MST" },
  { label: "CST (GMT-6:00)", value: "CST" },
  { label: "EST (GMT-5:00)", value: "EST" },
  { label: "GMT", value: "GMT" },
];

export const skills: { label: string; value: Skills | "" }[] = [
  { label: "UI/UX", value: "UI/UX" },
  { label: "Frontend", value: "Frontend" },
  { label: "Backend", value: "Backend" },
  { label: "Devops", value: "DevOps" },
  { label: "Cloud", value: "Cloud" },
  { label: "Testing", value: "Testing" },
  { label: "Databases", value: "Databases" },
  { label: "Machine Learning", value: "Machine Learning" },
];

export const RegistrationFormPage = () => {
  const auth = useAuth();
  const data = useData();
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [values, setValues] = useState<RegistrationValues>({
    position: "",
    bio: "",
    linkedin: "",
    city: "",
    country: "",
    timezone: "",
    bitbucket: "",
    skills: [],
    additionalSkills: "",
  });
  const [errors, setErrors] = useState<RegistrationErrors>({
    city: "",
    bio: "",
    country: "",
    timezone: "",
    bitbucket: "",
    position: "",
    linkedin: "",
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  const getError = (name: string, value: any) => {
    return (
      {
        city: !value ? "City is a required field" : "",
        country: !value ? "Country is a required field" : "",
        timezone: !value ? "Timezone is a required field" : "",
        bio: !value ? "Profile Summary is a required field" : "",
        bitbucket: !value ? "This is a required field" : "",
        position: name === "position" && getPositionError(value),
        linkedin: name === "linkedin" && getLinkedinError(value),
      }[name] || ""
    );
  };

  const onChange = (name: string, value: string) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: getError(name, value) }));
  };

  const onClick = () => {
    const errMessages = {
      bio: getError("bio", values.bio),
      city: getError("city", values.city),
      country: getError("country", values.country),
      timezone: getError("timezone", values.timezone),
      bitbucket: getError("bitbucket", values.bitbucket),
      skills: getError("skills", values.skills),
      position: getError("position", values.position),
      linkedin: getError("linkedin", values.linkedin),
    };

    if (
      errMessages.city ||
      errMessages.bio ||
      errMessages.country ||
      errMessages.timezone ||
      errMessages.bitbucket ||
      errMessages.skills ||
      errMessages.position ||
      errMessages.linkedin
    ) {
      setErrors(errMessages);

      triggerFailureToast(toast, {
        title: "Invalid Fields",
        description: "Please check your inputs and correct any invalid fields",
      });

      return;
    }

    onOpen();
  };

  const onSubmit = async () => {
    if (!auth) {
      return;
    }

    const id = deriveIdFromEmail(auth.email);

    const formData: Participant = {
      id: id || "",
      email: auth.email || "",
      fName: auth.displayName?.split(" ")[0] || "",
      lName: auth.displayName?.split(" ")[1] || "",
      position: values.position,
      bio: values.bio,
      linkedin: values.linkedin,
      city: values.city as City,
      country: values.country as Country,
      timezone: values.timezone as Timezone,
      bitbucket: values.bitbucket,
      skills: values.skills.concat(
        values.additionalSkills
          .split(",")
          .map((s) => s.trim())
          .filter((s) => s)
      ),
    };

    setDoc(doc(firestore, "participants", id || ""), formData).catch(() => {
      triggerFailureToast(toast, {
        title: "Error",
        description:
          "Oh no, an error occured! Please contact hackathon2024@apexon.com if the issue persists.",
      });
    });
  };

  const onChangeSkills = (name: string, value: string) => {
    if (!values.skills.find((skill) => skill === value)) {
      setValues((prev) => ({ ...prev, [name]: [value].concat(prev.skills).slice(0, 3) }));
    } else {
      setValues((prev) => ({ ...prev, [name]: prev.skills.filter((s) => s !== value) }));
    }
  };

  return (
    <FlowWrapper>
      {isOpen && (
        <ConfirmationModal
          title="Confirm Submission"
          body="Please ensure your details are accurate as you cannot make further changes once the form is submitted"
          action="Continue"
          type="neutral"
          onClose={onClose}
          onClick={onSubmit}
        />
      )}
      <Center>
        <Box padding="50px">
          <Box mb="30px" maxW="600px">
            <ApexonLogo />
            <Heading size="xs" mt="50px">
              Hello {auth?.displayName?.split(" ")[0]}!
            </Heading>
            <Text color="blue.500" mb="20px">
              {auth?.email}
            </Text>
            <Text color="muted">
              Innovate with Apexon at GEN AI HACKATHON! Introducing the much awaited hackathon in a new avatar!
              Invite everyone to indulge in the One Apexon spirit by sharing this form with your friends and
              colleagues! Let us participate as ONE Team, One Apexon and take a positive step forward!!
            </Text>
          </Box>
          <Box
            boxSizing="border-box"
            bg="white"
            borderRadius="lg"
            borderWidth="1px"
            borderColor="gray.200"
            maxW={{ md: "800px" }}
            p="20px"
          >
            <Stack w={{ md: "600px" }} spacing="12" px="6" py="6">
              <TextInput
                name="position"
                label="Job Title"
                value={values.position}
                error={errors.position}
                onChange={onChange}
                maxW="400px"
                required
              />

              <SelectInput
                name="city"
                label="City"
                value={values.city}
                error={errors.city}
                options={cities}
                onChange={onChange}
                maxW="400px"
                required
              />

              <SelectInput
                name="country"
                label="Country"
                value={values.country}
                error={errors.country}
                options={countries}
                onChange={onChange}
                maxW="400px"
                required
              />

              <SelectInput
                name="timezone"
                label="Timezone"
                value={values.timezone}
                error={errors.timezone}
                options={timezones}
                onChange={onChange}
                maxW="400px"
                required
              />

              <CheckboxInput
                name="skills"
                label="Technical Skills"
                helper="Select top 3 skills only"
                value={values.skills}
                options={skills}
                onChange={onChangeSkills}
              />

              <TextInput
                name="additionalSkills"
                label="Additional Skills"
                value={values.additionalSkills}
                helper="Provide a comma-separated list of skills"
                onChange={onChange}
                maxW="400px"
              />

              <RadioInput
                name="bitbucket"
                label="Do you have a bitbucket account with Apexon?"
                value={values.bitbucket}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                error={errors.bitbucket}
                onChange={onChange}
                required
              />

              <TextInput
                name="linkedin"
                label="LinkedIn Profile Link"
                value={values.linkedin}
                error={errors.linkedin}
                onChange={onChange}
                maxW="400px"
              />

              <TextareaInput
                name="bio"
                label="Profile Summary"
                value={values.bio}
                error={errors.bio}
                onChange={onChange}
                maxLength={300}
                helper={`${300 - values.bio.length} characters remaining`}
                required
              />
            </Stack>
          </Box>
          <Flex gap="10px" justify="flex-end" mt="20px">
            <Button variant="primary" isLoading={false} onClick={onClick}>
              Complete Registration
            </Button>
          </Flex>
        </Box>
      </Center>
    </FlowWrapper>
  );
};
